
// main-image-banner swiper
.main-image-banner .swiper-container {
    max-width: var(--container);
}
.main-image-banner .swiper-control {
    display: none;
    max-width: var(--container-lg);
}
.main-image-banner .swiper-prev {
    left: 0;
}
.main-image-banner .swiper-next {
    right: 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-image-banner .swiper-control {
        display: block;
    }
}

// main-image-banner
.main-image-banner {
    position: relative;
}
.main-image-banner__img {
    display: block;
    line-height: 0;
    border-radius: 10px !important;
    box-shadow: none !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .main-image-banner__img {
        border-radius: 20px !important;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
