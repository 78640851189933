
.notification-list {
    max-height: calc(100vh - (var(--header-body) + 66px));
    overflow-y: auto;
}
.notification-list__txt{
    font-size: 1.6rem;
    color:#333;
    text-align: center;
    padding:20px 12px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
