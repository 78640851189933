
// main-visual
.main-visual {
    width: 100%;
}
.main-visual .swiper-control {
    display: none;
    max-width: var(--container-lg);
}
.main-visual__inner {
    display: block;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    border-radius: 0 !important;
    margin: auto;
}
.main-visual__img {
    max-width: 100%;
    width: 100%;
    padding-top: calc(300/600 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual__img {
        padding-top: calc(300/1920 * 100%);
    }
}
@media (min-width: 1024px) {
    .main-visual .swiper-control {
        display: block;
    }
}
