
.content-area-before {
    cursor: pointer;

    flex-grow: 1;
    overflow-y: hidden;
    white-space: pre-line;
}
.content-area-before-text {
    position: relative;
    max-height: 134px;
    overflow-y: hidden;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 134px;
    //     background: linear-gradient(to bottom, transparent, white 67%);
    //     -webkit-transition: opacity ease-in-out 0.2s;
    //     -ms-transition: opacity ease-in-out 0.2s;
    //     transition: opacity ease-in-out 0.2s;
    // }
}

::v-deep .v-card--default__location + .v-card--default__content .content-box {
    max-height: 120px;
}

.content-area-after {
    max-height: 0px;
    overflow-y: hidden;
    -webkit-transition: max-height ease-in-out 0.2s;
    -ms-transition: max-height ease-in-out 0.2s;
    transition: max-height ease-in-out 0.2s;
}

::v-deep {
    .v-image__image--preload {
        filter: none !important;
    }
}
