
// main-wide-image-banner swiper
.main-wide-image-banner .swiper-control {
    display: none;
}
.main-wide-image-banner .swiper-prev {
    left: 0;
}
.main-wide-image-banner .swiper-next {
    right: 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-wide-image-banner .swiper-control {
        display: block;
    }
}

// main-wide-image-banner
.main-wide-image-banner {
    position: relative;
}
.main-wide-image-banner__img{
    display: block;
    line-height: 0;
    border-radius: 0 !important;
    box-shadow: none !important;
}
